import React, { Component } from "react";
import {
  History,
  HistoryMobile,
  Jakim,
  Muis,
  Thida,
  ThailandHalal,
  Kfri,
  Farm,
  Food,
  At,
  Kotra,
  KHalal,
  Hdc,
  Bsq,
  Chart,
  MapImage,
  Ifanca, ARA, HCCC, IDCP, Biotech, Smiic
} from "../images/index";

export class About extends Component {
  componentDidMount() {
    document.title = "KMF 할랄위원회";
  }
  render() {
    return (
      <div>
        <section>
          <h3 id="intro">KMF할랄위원회 소개</h3>
          <p>
            우리 (재)한국이슬람교(KMF)는 국내의 대표 이슬람 종교단체로 산하에 할랄위원회 를 두고 있으며 1994년부터 할랄인증 업무를 수행하고 있습니다.
            KMF할랄인증을 통해 국내 업체들의 대 이슬람권 수출에 불편함이 없도록 협조하고 있습니다. 할랄 인증은 모든 무슬림들이 종교적으로는 안전하고 위생적으로는
            깨끗한 먹거리와 서 비스를 제공받을 수 있도록 지원해야 하는 것으로 저희 교단의 의무이기도합니다. 한국이슬람교 할랄위원회는 내부로는 역량강화 및 전문가를
            양성하고 외부로는 말 레이시아 JAKIM, 싱가포르 MUIS, 대만 THIDA, 태국 CICOT, 미국 IFANCA과 상 호인정 협정을 맺었으며, 한국식품연구원(KFRI),
            (사)한국할랄수출협회와 MOU를 체결해 대내외적인 공신력을 갖추었습니다. 더 나아가 인도네시아의 BPJPH 등과 같은 여러 국가 할랄인증기관들과
            UAE의 ESMA, 터키 SMIIC 등과 같은 여러 국 가 할랄표준기구들과의 상호인정을 위한 시스템을 구축하고 있습니다.
          </p>
        </section>

        <section>
          <h3 id="history">연혁</h3>
          <img src={History} alt="" className="history" />
          <img src={HistoryMobile} alt="" className="history-mobile" />
        </section>

        <section>
          <h3 id="other-certifications">상호인정 해외인증기관</h3>
          <div className="flex-container">
            <div className="logo">
              <img src={Jakim} alt="" />
              <p className="label">말레이시아 JAKIM</p>
            </div>
            <div className="logo">
              <img src={Muis} alt="" />
              <p className="label">싱가포르 MUIS</p>
            </div>
            <div className="logo">
              <img src={Thida} alt="" />
              <p className="label">대만 THIDA</p>
            </div>
            <div className="logo">
              <img src={ThailandHalal} alt="" />
              <p className="label">태국 CICOT</p>
            </div>
            <div className="logo">
              <img src={Ifanca} alt="" style={{width: '119px'}} />
              <p className="label">미국 IFANCA</p>
            </div>
            <div className="logo">
              <img src={ARA} alt="" style={{width: '119px'}} />
              <p className="label">중국 ARA</p>
            </div>
            <div className="logo">
              <img src={HCCC} alt="" style={{width: '119px'}} />
              <p className="label">칠레 H.C.C.C</p>
            </div>
            <div className="logo">
              <img src={IDCP} alt="" style={{width: '119px'}} />
              <p className="label">필리핀 IDCP</p>
            </div>
          </div>
        </section>

        <section>
          <h3 id="collab">주요협력기관</h3>
          <div className="flex-container">
            <div className="logo">
              <img src={Kfri} alt="" />
            </div>
            {/*<div className="logo">
              <img src={Farm} alt="" />
            </div>
            <div className="logo">
              <img src={Food} alt="" />
            </div>*/}
            <div className="logo">
              <img src={At} alt="" />
            </div>
            <div className="logo">
              <img src={Kotra} alt="" />
            </div>
            <div className="logo">
              <img src={KHalal} alt="" />
            </div>
            <div className="logo">
              <img src={Hdc} alt="" />
            </div>
            <div className="logo">
              <img src={Bsq} alt="" />
            </div>
            <div className="logo">
              <img src={Biotech} alt="" style={{width: '119px'}} />
            </div>
            <div className="logo">
              <img src={Smiic} alt="" style={{width: '119px'}} />
            </div>
          </div>
        </section>

        <section>
          <h3 id="stats">인증 통계</h3>
          <img src={Chart} alt="" className="chart" />
        </section>

        <section>
          <h3 id="contact">연락처</h3>
          <img src={MapImage} alt="" className="map" />
          <p className="contact-info">
            주소: 서울 용산구 우사단로 10길 39 <br />
            전화번호 : 02)793-6908 내선 3
          </p>
        </section>
      </div>
    );
  }
}

export default About;
