import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {Pagination, PaginationItem} from '@material-ui/lab';
import {Grid} from "@material-ui/core";
import {dbURL} from "../resources/api";

function Notice() {
  useEffect(() => {
    document.title = "공지사항";
    // fetchNotices();
    noticeList();
  }, []);

  const [ notices, setItems ] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  // const fetchNotices = async () => {
  //   const data = await fetch("http://3.37.71.181/wp/?rest_route=/wp/v2/notices");
  //   const notices = await data.json();
  //   setItems(notices);
    // console.log(notices);
  // };

  const noticeList = () => {
    axios.get(dbURL + '/api/notice/list', ).then(function (response) {
      console.log('response');
      console.log(response);
      setItems(response.data)
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1)
  }

  return (
    <div className="page-container">
      <section>
        <ul className="notice-table">
          {notices
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((notice, index) => (
            <li key={notice.NoticeID} className="notice-row">
              <NavLink to={`/notices/${notice.NoticeID}`}>
                <div className="category">공지사항</div>
                <div className="title">{notice.Title}</div>
                <div className="date">작성일: {notice.UpdateDateTime}</div>
              </NavLink>
            </li>
          ))}
        </ul>
        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center">
          <Pagination
              count={Math.ceil(notices.length/rowsPerPage)}
              onChange={handleChangePage}
              renderItem={ (item) => (
                  <PaginationItem
                      onChange={handleChangePage}
                      {...item}
                  />
              )}
          />
        </Grid>
      </section>
    </div>
  );
}

export default Notice;
