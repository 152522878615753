import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {Grid} from "@material-ui/core";
import {Pagination, PaginationItem} from "@material-ui/lab";
import {dbURL} from "../resources/api";

function News() {
  useEffect(() => {
    document.title = "뉴스";
    // fetchNews();
    newsList();
  }, []);

  const [ news, setItems ] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  // const fetchNews = async () => {
  //   const data = await fetch("http://3.37.71.181/wp/?rest_route=/wp/v2/news");
  //   const news = await data.json();
  //   setItems(news);
    // console.log(news);
  // };

  const newsList = () => {
    axios.get(dbURL + '/api/news/kr/list', ).then(function (response) {
      console.log('response');
      console.log(response.data);
      if(response.data.length > 0) {
        setItems(response.data)
      }
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1)
  }

  return (
    <div className="page-container">
      <section>
        <ul className="notice-table">
          {news
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((newsItem) => (
            <li key={newsItem.NewsID} className="notice-row">
              <NavLink to={`/news/${newsItem.NewsID}`}>
                <div className="category">언론기사</div>
                <div className="title">{newsItem.Title}</div>
                <div className="date">작성일: {newsItem.UpdateDateTime}</div>
              </NavLink>
            </li>
          ))}
        </ul>
        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center">
          <Pagination
              count={Math.ceil(news.length/rowsPerPage)}
              onChange={handleChangePage}
              renderItem={ (item) => (
                  <PaginationItem
                      onChange={handleChangePage}
                      {...item}
                  />
              )}
          />
        </Grid>
      </section>
    </div>
  );
}

export default News;
