import React from 'react';
import { flowImage, flowImageMobile, clock, grid, thumbsup, network, shield, lock, algo, usageSample, usageSampleMobile, step1, step2, step3, step4 } from '../images/index';

function GuidePage() {
  return (
    <div>
      <section className="guide">
        <h3>블록체인 기반 실시간 할랄인증 서비스</h3>
        <p>
          <span>블록체인 기반 실시간 할랄인증 서비스란?</span> <br />
          블록체인 기술을 활용하여 인증정보를 안전하고 투명하게 관리하고, 그 정보를 실시간으로 바로 확인 해주는 서비스.
        </p>
        <img src={flowImage} alt="할랄 인증 블록체인 플로우" className="flow-image" />
        <img src={flowImageMobile} alt="할랄 인증 블록체인 플로우" className="flow-image-mobile" />
      </section>
      <section className="guide">
        <h3>서비스 장점</h3>
        <div className="guide-grid">
          <div className="item1">
            <img src={clock} alt="" />
            <h4>실시간 확인</h4>
            <p>
              할랄인증 정보 및 제품관련 정보 <br /> 언제 어디서나 확인 가능
            </p>
          </div>
          <div className="item2">
            <img src={grid} alt="" />
            <h4>스마트 관리</h4>
            <p>
              할랄인증 정보의 <br /> 쉽고 빠른 확인 및 관리
            </p>
          </div>
          <div className="item3">
            <img src={thumbsup} alt="" />
            <h4>신뢰도 UP</h4>
            <p>
              할랄인증 정보 위변조 불가 <br /> 블록체인 기술로 투명성 확보
            </p>
          </div>
          <div className="item4">
            <img src={network} alt="" />
            <h4>마케팅 강화</h4>
            <p>
              제품과 업체 동시 홍보 가능 <br /> 소비자 의견 수렴
            </p>
          </div>
        </div>
      </section>

      <section className="guide tc">
        <h3>실제 서비스 구현 화면 예시</h3>
        <div className="flex-guide">
          <div className="step first">
            <img src={step1} alt="" />
            1. 할랄 인증을 찾으세요.
          </div>
          <div className="step second">
            <img src={step2} alt="" />
            2. 할랄인증 QR 코드를 스캔 하세요.
          </div>
        </div>
        <br />
        <div className="flex-guide">
          <div className="step">
            <img src={step3} alt="" />
            3. 할랄 인증된 제품을 확인하세요.
          </div>
        </div>
        <br />
        <div className="flex-guide">
          <div className="step">
            <img src={step4} alt="" />
          </div>
        </div>
        {/* <img className="usage-sample" src={usageSample} alt="" /> */}
        {/* <img className="usage-sample-mobile" src={usageSampleMobile} alt="" /> */}
      </section>

      <section className="guide">
        <h3>Blockchain 기술 특징</h3>
        <p>블록체인 기술을 도입하여 더욱 투명하고 간편하게 할랄인증 확인</p>
        <div className="guide-grid">
          <div className="item5">
            <img src={shield} alt="" />
            <h4>
              믿을수 있는 분산형 <br /> 원장 기록 및 보존
            </h4>
            <p>인후 정보 즉시 블록체인에 기록</p>
          </div>
          <div className="item6">
            <img src={lock} alt="" />
            <h4>
              블록체인의 타임스탬프와 <br /> 위변조방지 기능
            </h4>
            <p>
              안전하고 유일한 테이터 <br /> 출처 제공
            </p>
          </div>
          <div className="item7">
            <img src={algo} alt="" />
            <h4>
              블록체인의 합의알고리즘 <br /> 및 스마트계약
            </h4>
            <p>
              데이터의 실시간 공유 <br /> 및 추적 가능
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default GuidePage;
