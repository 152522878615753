import React, { Component } from "react";
import { KoreanIslamLogo } from "../images/index";

export class Footer extends Component {
  render() {
    return (
      <div>
        <footer>
          <div className="logo">
            <a href="http://www.koreaislam.org" target="_blank">
              <img src={KoreanIslamLogo} alt="" />
            </a>
          </div>
          <div className="address">
            서울 용산구 우사단로 10길 39 <br />
            Tel: 02-793-6908 Fax: 02-798-9782 <br />
            <a href="http://www.koreaislam.org" target="_blank">
              www.koreaislam.org
            </a>{" "}
            <br />
            COPYRIGHT 2019 KOREAN MUSLIM FEDERATION ALL RIGHTS RESERVED
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
