import React from 'react';
import './scss/styles.scss';
import { Route, Switch, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Cert from './components/Cert';
import Notice from './components/Notice';
import News from './components/News';
import Error from './components/Error';
import Footer from './components/Footer';
import NewsDetail from './components/NewsDetail';
import NoticeDetail from './components/NoticeDetail';
import GuidePage from './components/GuidePage';
import GuidePageEn from './components/GuidePageEn';
import Fatwa from "./components/Fatwa";
import FatwaDetail from "./components/FatwaDetail";
import Popup from "./components/Popup";
import Popup2 from "./components/Popup2";

function App() {
  let location = useLocation();
  return (
    location.pathname === "/popup" ?
        <Route path="/popup" component={Popup} exact />
        :
        location.pathname === "/popup2" ?
            <Route path="/popup2" component={Popup2} exact />
            :
        <div className="main-container">
          <Header />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/cert" component={Cert} />
            <Route path="/notices" exact component={Notice} />
            <Route path="/notices/:id" component={NoticeDetail} />
            <Route path="/news" exact component={News} />
            <Route path="/news/:id" component={NewsDetail} />
            <Route path="/guide" component={GuidePage} />
            <Route path="/guideEn" component={GuidePageEn} />
            <Route path="/fatwas" exact component={Fatwa} />
            <Route path="/fatwas/:id" exact component={FatwaDetail} />
            <Route component={Error} />
          </Switch>
          <Footer />
        </div>
  );
}

export default App;
