import React, { useState, useEffect } from "react";
import {stateToHTML} from "draft-js-export-html";
import {convertFromRaw} from 'draft-js'
import axios from "axios";
import {dbURL} from "../resources/api";

function NewsDetail({ match }) {
  useEffect(() => {
    document.title = "뉴스";
    // eslint-disable-next-line
    fetchNews();
    // console.log(match);
  }, []);

  const [msgTitle, setMsgTitle] = useState("");
  const [msgDate, setMsgDate] = useState("");
  const [msgContent, setMsgContent] = useState("");

  const fetchNews = async () => {

    // axios.get('http://3.37.71.181:3100/api/news/kr/find', {
    axios.get(dbURL + '/api/news/kr/find', {
      params: {
        id: match.params.id
      }
    }).then(function (response) {
      console.log('response');
      console.log(response.data);
      const options = {
        entityStyleFn: (entity) => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'image') {
            const data = entity.getData();
            return {
              element: 'IMAGE',
              attributes: {
                src: data.url,
              },
            };
          }
        },
      };

      setMsgTitle(response.data[0].Title);
      setMsgDate(response.data[0].UpdateDateTime);
      setMsgContent(stateToHTML(convertFromRaw(JSON.parse(response.data[0].Content)), options));
      // setHTML(convertFromRaw(JSON.parse(response.data[0].Content)));
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });

    // const data = await fetch(`http://3.37.71.181/wp/?rest_route=/wp/v2/news/${match.params.id}`);
    // const data = await fetch(`http://3.37.71.181:3100/api/news/kr/find?id=${match.params.id}`);
    // const data = await fetch(`http://3.37.71.181:3100/api/news/kr/find?id=3`);
    // console.log(data);
    // const newsDetail = await data.json();
    // setItems(newsDetail);



    // console.log(newsDetail);
  };

  // const [ newsDetail, setItems ] = useState({
  //   title: {
  //     rendered: ""
  //   },
  //   content: {
  //     rendered: ""
  //   },
  //   date: ""
  // });

  return (
    <div className="notice-detail-container">
      <div className="notice-header">
        <div className="category">언론기사</div>
        {/*<div className="title">{newsDetail.title.rendered}</div>*/}
        {/*<div className="date">작성일: {newsDetail.date.split("T")[0]}</div>*/}
        <div className="title">{msgTitle}</div>
        <div className="date">작성일: {msgDate}</div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: msgContent }} />
    </div>
  );
}

export default NewsDetail;
