const _server = 'https://adc.kmfhalal.org'
const _serverIp = 'http://3.37.71.181'
const _serverLocalIp = 'http://localhost'
const _port = '3100'
const _domainURL = `${_server}`
const _baseURL = `${_serverIp}:${_port}`
const _baseLocalURL = `${_serverLocalIp}:${_port}`


// export const dbURL = _baseLocalURL;
// export const dbURL = _baseURL;
export const dbURL = _domainURL;
