import React, { Component } from "react";
import {
  popupImage,
} from "../images/index";

function Popup(){
    const todayInvisible = () => {
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem('hasVisitedBefore', expires);
        window.close();
    }

  return (
    <div>
        <img src={popupImage} alt="" className="map" />
        <div style={{textAlign: 'center', paddingBottom: '2em'}}>
            <button onClick={todayInvisible} className="certificate-cta" style={{display: 'initial'}}>오늘하루 그만보기</button>
        </div>
    </div>
  );
}

export default Popup;
