import React, { useState, useEffect } from "react";
import axios from "axios";
import {stateToHTML} from "draft-js-export-html";
import {convertFromRaw} from "draft-js";
import {dbURL} from "../resources/api";
// import { NavLink } from "react-router-dom";

function NoticeDetail({ match }) {
  useEffect(() => {
    document.title = "공지사항";
    // eslint-disable-next-line
    // fetchNews();
    // console.log(match);
    noticeDetailFind(match.params.id)
  }, []);

  const [msgTitle, setMsgTitle] = useState("");
  const [msgDate, setMsgDate] = useState("");
  const [msgContent, setMsgContent] = useState("");
  const [fileAppendHtml, setFileAppendHtml] = useState("");

  // const fetchNews = async () => {
  //   const data = await fetch(`http://3.37.71.181/wp/?rest_route=/wp/v2/notices/${match.params.id}`);
  //   const noticeDetail = await data.json();
  //   setItems(noticeDetail);
    // console.log(noticeDetail.date);
  // };

  const noticeDetailFind = async (id) => {
    axios.get( dbURL + '/api/notice/find', {
      params: {
        id: match.params.id
      }
    }).then(function (response) {
      console.log('response');
      console.log(response.data);
      const options = {
        entityStyleFn: (entity) => {
          const entityType = entity.get('type').toLowerCase();
          if (entityType === 'image') {
            const data = entity.getData();
            return {
              element: 'IMAGE',
              attributes: {
                src: data.url,
              },
            };
          }
        },
      };

      setMsgTitle(response.data[0].Title);
      setMsgDate(response.data[0].UpdateDateTime);
      setMsgContent(stateToHTML(convertFromRaw(JSON.parse(response.data[0].Content)), options));

      noticeDetailFiles(id);
      // setCircular(false);
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }

  const  noticeDetailFiles = async (id) => {
    axios.get(dbURL + '/api/notice/files', {
      params: {
        id: id
      }
    }).then(function (response) {
      console.log('response');
      console.log(response.data);
      let fileAppend = "";
      for(let i = 0; i < response.data.length; i++) {
        console.log(response.data[i].FileRealName);
        fileAppend += "<div><a href='" + dbURL + "/api/attached/download?id="+response.data[i].AttachedID+"'>" + response.data[i].FileRealName + "</a></div>";
      }
      setFileAppendHtml(fileAppend);
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }

  return (
    <section className="notice-detail-container">
      {/* <p>{noticeDetail.id}</p> */}
      <div className="notice-header">
        <div className="category">공지사항</div>
        <div className="title">{msgTitle}</div>
        <div className="date">작성일: {msgDate}</div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: msgContent }} />
      <div dangerouslySetInnerHTML={ {__html: fileAppendHtml} }></div>
    </section>
  );
}

export default NoticeDetail;
