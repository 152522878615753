import React, { useState, useEffect } from "react";
import {
  Jakim,
  Muis,
  Thida,
  ThailandHalal,
  Kfri,
  Farm,
  Food,
  At,
  Kotra,
  KHalal,
  Hdc,
  Bsq,
  Ifanca,
  ARA, HCCC, IDCP, Biotech, Smiic
} from "../images/index";
import HalalCarousel from "./HalalCarousel";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {dbURL} from "../resources/api";

function Home() {
  useEffect(() => {
    document.title = "KMF";
    // fetchNotices();
    noticeList();

    if(localStorage.getItem('hasVisitedBefore') < new Date()) {
      window.open("/popup",'popup','top=200px,left=200px,width=1000px,height=600px,scrollbars=yes');
    }

    if(localStorage.getItem('hasVisitedBefore2') < new Date()) {
      window.open("/popup2",'popup2','top=600px,left=400px,width=1000px,height=600px,scrollbars=yes');
    }

  }, []);

  const [notices, setItems] = useState([]);

  // const fetchNotices = async () => {
  //   const data = await fetch("http://3.37.71.181/wp/?rest_route=/wp/v2/notices");
  //   const notices = await data.json();
  //   setItems(notices);
  //   // console.log(notices);
  // };

  const noticeList = () => {
    axios.get(dbURL + '/api/notice/list', ).then(function (response) {
      console.log('response');
      console.log(response);
      setItems(response.data)
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }


  return (
    <div>
      <div className="index-container">
        <div className="col-1">
          {/* <a
            href="http://halal.koreaislam.org/KMFHALAL/Login"
            className="index-cta"
            target="_blank"
            rel="noopener noreferrer"
          >
            할랄 인증 신청
          </a> */}
          <NavLink exact to="/guide" className="index-cta">
            블록체인 기반 실시간 할랄인증 서비스
          </NavLink>
          <div className="certificates">
            <h3>상호인정 해외인증기관</h3>
            <div className="flex-body">
              <div className="flex-item">
                <img src={Jakim} alt="" />
                <p>말레이시아 JAKIM</p>
              </div>
              <div className="flex-item">
                <img src={Muis} alt="" />
                <p>싱가포르 MUIS</p>
              </div>
              <div className="flex-item">
                <img src={Thida} alt="" />
                <p>대만 THIDA</p>
              </div>
              <div className="flex-item">
                <img src={ThailandHalal} alt="" />
                <p>태국 CICOT</p>
              </div>
              <div className="flex-item">
                <img src={Ifanca} alt="" />
                <p>미국 IFANCA</p>
              </div>
              <div className="flex-item">
                <img src={ARA} alt="" />
                <p>중국 ARA</p>
              </div>
              <div className="flex-item">
                <img src={HCCC} alt="" />
                <p>칠레 H.C.C.C</p>
              </div>
              <div className="flex-item">
                <img src={IDCP} alt="" />
                <p>필리핀 IDCP</p>
              </div>
            </div>
          </div>
          <div className="notices">
            <h3>공지사항</h3>
            <div className="notice">
              <ul>
                {notices.slice(0, 4).map((notice) => (
                  <li key={notice.NoticeID}>
                    <NavLink to={`/notices/${notice.NoticeID}`}>
                      {/* <div className="category">공지사항</div> */}
                      <div className="title">{notice.Title}</div>
                      {/* <div className="date">작성일: {notice.date.split("T")[0]}</div> */}
                    </NavLink>
                  </li>
                ))}
              </ul>
              {/* <ul>
                <li>
                  <a href="">2019년 국민권익위원회 주관 청렴도 측정 결과 2019년 국민권익위원회asdfasdfasdf</a>
                </li>
                <li>
                  <a href="">2019년 국민권익위원회 주관 청렴도 측정 결과 2019년 국민권익위원회asdfasdfasdf</a>
                </li>
                <li>
                  <a href="">2019년 국민권익위원회 주관 청렴도 측정 결과 2019년 국민권익위원회asdfasdfasdf</a>
                </li>
                <li>
                  <a href="">2019년 국민권익위원회 주관 청렴도 측정 결과 2019년 국민권익위원회asdfasdfasdf</a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>

        <div className="col-2">
          <HalalCarousel />
        </div>
      </div>

      <div className="collab-companies">
        <h3>주요협력기관</h3>
        <div className="flex-body">
          <div className="flex-item">
            <img src={Kfri} alt="" />
          </div>
          {/*<div className="flex-item">
            <img src={Farm} alt="" />
          </div>
          <div className="flex-item">
            <img src={Food} alt="" />
          </div>*/}
          <div className="flex-item">
            <img src={At} alt="" />
          </div>
          <div className="flex-item">
            <img src={Kotra} alt="" />
          </div>
          <div className="flex-item">
            <img src={KHalal} alt="" />
          </div>
          <div className="flex-item">
            <img src={Hdc} alt="" />
          </div>
          <div className="flex-item">
            <img src={Bsq} alt="" />
          </div>
          <div className="flex-item" style={{margin: 'auto'}}>
            <img src={Biotech} alt="" />
          </div>
          <div className="flex-item">
            <img src={Smiic} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
