import React, {useEffect, useState} from 'react';
import { stepsDesktop, stepsMobile, halalFlow } from '../images/index';
import axios from "axios";
import {dbURL} from "../resources/api";
// import { NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";

function Cert() {
  useEffect(() => {
    document.title = '할랄인증';
    // fetchNotices();
    attachedList();
  }, []);

  const [attachList1, setAttachList1] = useState({});
  const [attachList2, setAttachList2] = useState({});

  const attachedList = () => {
    // axios.get('http://3.37.71.181:3100/api/attached/list', ).then(function (response) {
    axios.get(dbURL + '/api/attached/list', ).then(function (response) {
      console.log('response');
      console.log(response.data);
      if(response.data.length > 0) {
        setAttachList1(response.data[0]);
        setAttachList2(response.data[1]);
      }
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }

  return (
    <div>
      <section>
        <h3 id="intro">인증개요</h3>
        <p>
          이슬람에서 허용(HALAL)과 금기(HARAM)는 이슬람의 경전인 꾸란과 예언자 무함마드의 언행록인 하디스의 가르침에 기초하여 시작됩니다. 할랄과 하람에 관하여 꾸란 2장168절에는 다음과 같이 언급하고 있습니다. “오 사람들이여! 땅 위에 있는 허용된 좋은 것만을 먹도록 하라, 그리고 사탄의 발자취를 따르지 말라, 실로 그것은 너희들의 사악한 적이니라.” 유일신 하나님(Allah)의 말씀에 따라 무슬림들은 할랄, 즉 하나님께서 허용한 것을 먹고 마시며 취(取)합니다. 그러므로 할랄은 무슬림들의 일상이며 삶 그 자체입니다. 할랄인증은 식품에서 시작하여 식품첨가제, 음료, 화장품, 건강기능식품, 의약품, 생활용품, 관광, 물류 등 다양한 분야로 확대 되고 있습니다. 이미 할랄 시장은 연평균 10%이상의 성장세를 보이고 있고 우리나라 제품의 이슬람권 수출액은 이미 10억 달러를 넘어섰습니다. 이는 엄청난 성장 속도가 아닐 수 없으며
          앞으로 더욱 빠르게 증가 할 것으로 예상됩니다. 더불어 무슬림 소비자들에게 맞는 서비스를 위해 할랄인증은 선택이 아닌 필수가 될 것입니다.
        </p>
      </section>

      <section>
        <h3 id="steps">인증절차</h3>
        <img src={halalFlow} alt="" className="halal-flow" />
        {/* <img src={stepsDesktop} alt="" className="steps-desktop" /> */}
        {/* <img src={stepsMobile} alt="" className="steps-mobile" /> */}
      </section>

      <section>
        <h3 id="documents">구비서류</h3>
        {/* <ol className="documents">
          <li>할랄인증 신청 제품유형에 맞는 구비서류 목록 확인 후 해당 서류 제출</li>
          <li>인증계약서 작성하여 보관 및 제출 -(첨부) 인증계약서</li>
        </ol> */}
        <ol className="documents-new">
          <li>아래 KMF 할랄인증 구비서류 목록 확인 후 신청 제품 유형에 맞게 서류 준비하여 제출</li>
        </ol>
        <p>
          {/*<a href="http://3.37.71.181/wp/?attachment_id=261" target="_blank">*/}
          <a href={dbURL + "/api/attached/download?id=" +attachList1.AttachedID} target="_blank">
            KMF 할랄인증 구비서류 목록.zip
          </a>
        </p>
        <p>
          {/*<a href="http://3.37.71.181/wp/?attachment_id=260" target="_blank">*/}
          <a href={dbURL + "/api/attached/download?id=" +attachList2.AttachedID} target="_blank">
            KMF 식당 할랄인증 구비서류 목록.zip
          </a>
        </p>
      </section>

      <section>
        <h3 id="price">인증비용</h3>
        <table className="price-table">
          <tbody>
            <tr>
              <td className="label" rowSpan="2">서류심사비</td>
              <td>
                <b>1년 인증</b>
              </td>
              <td>
                <b>3년 인증</b>
              </td>
            </tr>
            <tr>
              <td>
                품목당 10만원
              </td>
              <td>
                품목당 30만원
              </td>
            </tr>
            <tr>
              <td className="label" rowSpan="2">
                현장 심사비 <br /> (공장별 부과)
              </td>
              <td>
                <b>1년 인증</b>
              </td>
              <td>
                <b>3년 인증</b>
              </td>
            </tr>
            <tr>
              <td>
                서울 30만원 <br />
                경기 40만원 <br />
                충청 50만원 <br />
                강원,영남,호남 60만원 <br />
                제주 및 도서지역 80만원
              </td>
              <td>
                서울 90만원 <br />
                경기 120만원 <br />
                충청 150만원 <br />
                강원,영남,호남 180만원 <br />
                제주 및 도서지역 240만원
              </td>
            </tr>
            <tr>
              <td className="label" rowSpan="2">할랄인증료</td>
              <td>
                <b>1년 인증</b>
              </td>
              <td>
                <b>3년 인증</b>
              </td>
            </tr>
            <tr>
              <td>
                <ol>
                  <li>기존 할랄인증 : 품목당 60만원</li>
                  <li>블록체인 실시간 할랄인증 (QR코드발행) : 품목당 110만원 </li>
                </ol>
              </td>
              <td>
                <ol>
                  <li>기존 할랄인증 : 품목당 180만원</li>
                  <li>블록체인 실시간 할랄인증 (QR코드발행) : 품목당 330만원 </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td className="label">인증서 재발행/추가 발행 비용</td>
              <td colSpan="2">
                건당 3만원 <br />
                <ol>
                  <li>판매사 추가 기입 요청 시</li>
                  <li>제품 분리 발행 요청 시</li>
                  <li>기타사유로 인한 재발행 요청 시</li>
                  <li>지면 인증서 발행 요청 시</li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <a href="http://halal.koreaislam.org/KMFHALAL/Login" target="_blank" className="certificate-cta">
          인증신청
        </a>
      </section>
    </div>
  );
}

export default Cert;
