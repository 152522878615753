import React, { Component } from "react";
import {
  popupImage2,
} from "../images/index";
import {Link} from "react-router-dom";

function Popup2(){
    const todayInvisible = () => {
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem('hasVisitedBefore2', expires);
        window.close();
    }

  return (
    <div>
        <img src={popupImage2} alt="" className="map" />
        <Link target={"_blank"} to={{pathname:"https://www.kfri.re.kr/?m=bbs&bid=notice&uid=37337"}} style={{paddingLeft:"50px"}}>식품기술대상 모집 공고 바로 가기
        </Link>
        <div style={{textAlign: 'center', paddingBottom: '2em'}}>
            <button onClick={todayInvisible} className="certificate-cta" style={{display: 'initial'}}>오늘하루 그만보기</button>
        </div>
    </div>
  );
}

export default Popup2;
