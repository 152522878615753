import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { newsImage1, newsImage2, newsImage3, blockchainImage, Chart, flowImageMobile } from "../images/index";
import { NavLink } from "react-router-dom";
import {useHistory} from "react-router";

function HalalCarousel() {

    const history = useHistory();

    const handleClick = async () => {
        console.log('aaaa')
    }


    return (
    <Carousel showStatus={false} autoPlay={true} infiniteLoop={true} interval={5000} showThumbs={false} showArrows={false} centerMode={true} centerSlidePercentage={100}>
      <NavLink to="/news/5">
        <img src={newsImage1} alt=""onClick={()=> handleClick()} />
        <div className="content-body">
          <h3>태국 할랄인증기관(CICOT)과 상호인정협약 체결</h3>
          <p>태국 방콕에서 열린 Thailand Halal Assembly 2019에 참가한 재단법인 한국이슬람교(Korea Muslim Federation, 이하 ‘KMF’, 이사장 김동억) 할랄위원회가 2019년 12월 21일 태국의 할랄인증기관인 태국이슬람중앙회(Central Islamic Council of Thailand, 이하 ‘CICOT’)와 각자의 할랄인증에 대한 효력을 상호 인정하는 협약을 체결하였다.</p>
        </div>
      </NavLink>
      <NavLink to="/news/4">
        <img src={newsImage2} alt="" onClick={() => {history.go('/news/4');}} />
        <div className="content-body">
          <div className="content-body">
            <h3>인도네시아 할랄청(BPJPH)과 상호인정 협의</h3>
            <p>김동억 KMF 재단 이사장과 할랄위원회는 BPJPH(인도네시아 할랄청) Dr. Sukoso청장을 예방하여 상호인정에 대해 논의하였다. 김동억 이사장은 KMF가 한국의 가장 권위 있는 ...</p>
          </div>
        </div>
      </NavLink>
      <NavLink to="/news/3">
        <img src={newsImage3} alt="" onClick={() => {history.go('/news/3');}} />
        <div className="content-body">
          <div className="content-body">
            <h3>주한 이슬람권 외교관 초청행사 개최</h3>
            <p>재단법인 한국이슬람교(Korea Muslim Federation, 이하 ‘KMF’, 이사장 김동억)는 2019년 4월 9일 남이섬에서 “주한 이슬람권 외교관 초청 할랄 한우 시식회”를 개최하였다 ...</p>
          </div>
        </div>
      </NavLink>
      <NavLink to="/guide">
        <img className="blockchain-image" src={flowImageMobile} alt="" onClick={() => {history.go('/guide');}} />
        <div className="content-body">
          <h3>블록체인 기반 실시간 할랄인증 서비스</h3>
          <p>블록체인 기술을 적용하여 할랄인증의 신뢰도와 투명성을 확보하고, 소비자가 직접 QR코드 스캔을 통해 실시간으로 간편하게 할랄인증 정보를 확인할 수 있다. 나아가 제품과 고객업체의 마케팅채널로도 활용할 수 있다.</p>
        </div>
      </NavLink>
      <div>
        <h3 className="chart-title">KMF 할랄인증 통계</h3>
        <img src={Chart} alt="" className="carousel-chart" />
      </div>
    </Carousel>
  );
}

export default HalalCarousel;
