import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// import logo from "../images/halal-logo.png";
import { Logo } from "../images/index";

export class Header extends Component {
  render() {
    return (
      <header>
        <div className="desktop-header">
          <NavLink exact to="/" className="logo">
            <img src={Logo} alt="" />
            <div className="title">
              Korea Muslim Federation <br />
              Halal Commitee
            </div>
          </NavLink>
          <nav>
            <div className="lang">
              <a href="http://kmfhalal.org/" className="active">
                Korean
              </a>{" "}
              ・ <a href="http://kmfhalal.org/en">English</a>
            </div>
            <div className="menu-container">
              <div className="menu">
                <NavLink
                  exact
                  to="/about"
                  activeClassName="navlink-active"
                  className="header-menu"
                >
                  KMF 할랄위원회
                </NavLink>
                <ul className="dropdown">
                  <li>
                    <a href="/about#intro">KMF할랄위원회 소개</a>
                  </li>
                  <li>
                    <a href="/about#history">연혁</a>
                  </li>
                  <li>
                    <a href="/about#other-certifications">
                      상호인정 해외인증기관
                    </a>
                  </li>
                  <li>
                    <a href="/about#collab">주요협력기관</a>
                  </li>
                  <li>
                    <a href="/about#stats">인증 통계</a>
                  </li>
                  <li>
                    <a href="/about#contact">연락처</a>
                  </li>
                </ul>
              </div>

              <div className="menu">
                <NavLink
                  exact
                  to="/cert"
                  activeClassName="navlink-active"
                  className="header-menu"
                >
                  할랄인증
                </NavLink>
                <ul className="dropdown">
                  <li>
                    <a href="/cert#intro">인증개요</a>
                  </li>
                  <li>
                    <a href="/cert#steps">인증절차</a>
                  </li>
                  <li>
                    <a href="/cert#documents">구비서류</a>
                  </li>
                  <li>
                    <a href="/cert#price">인증비용</a>
                  </li>
                  <li>
                    <a
                      href="http://halal.koreaislam.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      인증신청
                    </a>
                  </li>
                  <li>
                    <NavLink exact to="/guide">
                      블록체인 인증
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="menu">
                <NavLink
                  exact
                  to="/notices"
                  activeClassName="navlink-active"
                  className="header-menu"
                >
                  공지사항
                </NavLink>
              </div>

              <div className="menu">
                <NavLink
                  exact
                  to="/news"
                  activeClassName="navlink-active"
                  className="header-menu"
                >
                  뉴스
                </NavLink>
              </div>

              <div className="menu">
                <NavLink
                    exact
                    to="/fatwas"
                    activeClassName="navlink-active"
                    className="header-menu"
                >
                  KMF 샤리아위원회 파트와
                </NavLink>
              </div>

              <div className="menu certificate-btn">
                <a
                  href="http://halal.koreaislam.org/"
                  className="round-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  할랄 인증신청
                </a>
              </div>
            </div>
          </nav>
        </div>

        <div className="mobile-header">
          <div className="top-section">
            <NavLink to="/" className="logo">
              <img src={Logo} alt="" />
              <div className="title">
                Korea Muslim Federation <br />
                Halal Commitee
              </div>
            </NavLink>
            <div className="right-nav">
              <div className="lang">
                <a href="http://kmfhalal.org/" className="active">
                  KR
                </a>{" "}
                ・ <a href="http://kmfhalal.org/en">EN</a>
              </div>
              <div className="menu certificate-btn">
                <a
                  href="http://halal.koreaislam.org/"
                  className="round-btn"
                  target="_blank"
                >
                  할랄 인증신청
                </a>
              </div>
            </div>
          </div>
          <div className="main-menus">
            <ul>
              <li>
                <NavLink to="/about" activeClassName="navlink-active">
                  KMF 할랄위원회
                </NavLink>
              </li>
              <li>
                <NavLink to="/cert" activeClassName="navlink-active">
                  할랄인증
                </NavLink>
              </li>
              <li>
                <NavLink to="/notices" activeClassName="navlink-active">
                  공지사항
                </NavLink>
              </li>
              <li>
                <NavLink to="/news" activeClassName="navlink-active">
                  뉴스
                </NavLink>
              </li>
              <li>
                <NavLink to="/fatwas" activeClassName="navlink-active">
                  KMF 샤리아위원회 파트와
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
