import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {Pagination, PaginationItem} from '@material-ui/lab';
import {Grid} from "@material-ui/core";
import {dbURL} from "../resources/api";

function Fatwa() {
  useEffect(() => {
    document.title = "KMF 샤리아위원회 파트와";
    // fetchFatwas();
    fatwaList();
  }, []);

  const [ fatwas, setItems ] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  // const fetchfatwas = async () => {
  //   const data = await fetch("http://3.37.71.181/wp/?rest_route=/wp/v2/fatwas");
  //   const fatwas = await data.json();
  //   setItems(fatwas);
    // console.log(fatwas);
  // };

  const fatwaList = () => {
    axios.get(dbURL + '/api/fatwa/list', ).then(function (response) {
      console.log('response');
      console.log(response);
      if(response.data.length > 0) {
        setItems(response.data)
      }
    }).catch(function (error) {
      console.log('error');
      console.log(error);
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1)
  }

  return (
    <div className="page-container">
      <section>
        <ul className="notice-table">
          {fatwas
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((fatwa, index) => (
            <li key={fatwa.FatwaID} className="notice-row">
              <NavLink to={`/fatwas/${fatwa.FatwaID}`}>
                <div className="category">KMF 샤리아위원회 파트와</div>
                <div className="title">{fatwa.Title}</div>
                <div className="date">작성일: {fatwa.UpdateDateTime}</div>
              </NavLink>
            </li>
          ))}
        </ul>
        <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center">
          <Pagination
              count={Math.ceil(fatwas.length/rowsPerPage)}
              onChange={handleChangePage}
              renderItem={ (item) => (
                  <PaginationItem
                      onChange={handleChangePage}
                      {...item}
                  />
              )}
          />
        </Grid>
      </section>
    </div>
  );
}

export default Fatwa;
