import React, { useEffect } from 'react';
import { flowImageEn, flowImageMobileEn, clock, grid, thumbsup, network, shield, lock, algo, usageSample, usageSampleMobile, step1, step2, step3, step4 } from '../images/index';

export default function GuidePageEn() {
  useEffect(() => {
    document.title = '할랄인증';
    // fetchNotices();
  }, []);
  return (
    <div>
      <section className="guide">
        <h3>Real time blockchain based Halal Certification</h3>
        <p>
          <span>What is it?</span> <br />
          By using the Blockchain as a service halal certifications are secure and customers are able to check the validity of a halal certification in real time by scanning a QR code on the product.
        </p>
        <img src={flowImageEn} alt="할랄 인증 블록체인 플로우" className="flow-image" />
        <img src={flowImageMobileEn} alt="할랄 인증 블록체인 플로우" className="flow-image-mobile" />
      </section>
      <section className="guide">
        <h3>Benefits</h3>
        <div className="guide-grid">
          <div className="item1">
            <img src={clock} alt="" />
            <h4>Real Time Data</h4>
            <p>
              Check Halal Certification and <br /> detailed product information
            </p>
          </div>
          <div className="item2">
            <img src={grid} alt="" />
            <h4>Smart Management</h4>
            <p>
              Manage Halal Certificates with ease <br /> via our management system
            </p>
          </div>
          <div className="item3">
            <img src={thumbsup} alt="" />
            <h4>Improve Trust</h4>
            <p>
              Immutable Certification Data and <br /> improved transparency
            </p>
          </div>
          <div className="item4">
            <img src={network} alt="" />
            <h4>Better Marketing</h4>
            <p>
              Promote both the product and company, <br /> direct interactions with customers
            </p>
          </div>
        </div>
      </section>

      <section className="guide tc">
        <h3>Example</h3>
        <div className="flex-guide">
          <div className="step first">
            <img src={step1} alt="" />
            1. Look for the Halal Certification.
          </div>
          <div className="step second">
            <img src={step2} alt="" />
            2. Scan the QR code with your phone.
          </div>
        </div>
        <br />
        <div className="flex-guide">
          <div className="step">
            <img src={step3} alt="" />
            3. Verify in real time.
          </div>
        </div>
        <br />
        <div className="flex-guide">
          <div className="step">
            <img src={step4} alt="" />
          </div>
        </div>
        {/* <img className="usage-sample" src={usageSample} alt="" /> */}
        {/* <img className="usage-sample-mobile" src={usageSampleMobile} alt="" /> */}
      </section>

      <section className="guide">
        <h3>Advantages of the Blockchain</h3>
        <p>A much secure and simplified Halal verifications which the customers can scan and verify on the go.</p>
        <div className="guide-grid">
          <div className="item5">
            <img src={shield} alt="" />
            <h4>
              Decentralized Data for <br /> Better Security
            </h4>
            <p>Verify certifications on the go</p>
          </div>
          <div className="item6">
            <img src={lock} alt="" />
            <h4>
              Immutable Data through <br /> Blockchain's Time stamp
            </h4>
            <p>Safe and secure data</p>
          </div>
          <div className="item7">
            <img src={algo} alt="" />
            <h4>
              Trustless Algorithm for <br /> Smart Contracts
            </h4>
            <p>Real Time Data and Traceability</p>
          </div>
        </div>
      </section>
    </div>
  );
}
